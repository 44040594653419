.container {
  background: rgb(39, 39, 39);
  min-height: 100vh;
}

.content {
  display: grid;
  grid-template-columns: 100px auto 100px;
}

.card {
  grid-column: 2/4;
  background-color: #18181b;
  margin: 0px 50px 20px 20px;
  border-radius: 15px;
  box-shadow: 2px 2px #595959;
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr;
}

.details {
  padding: 40px 40px 0 40px;
}

/* On screens that are 772px wide or less */
@media screen and (max-width: 772px) {
  .content {
    display: block;
  }
  .card {
    margin: 0px 10px 20px 10px;
  }
}

/* On screens that are 450px wide or less */
@media screen and (max-width: 650px) {
  .details {
    padding: 10px;
  }
}