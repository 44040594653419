.smallHead {
  margin: 0;
  font-size: 3em;
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-weight: bold;
}

.smallHeadTyped {
  margin: 0;
  font-size: 1.5em;
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
}

.largeHead {
  margin: 0;
  font-size: 3em;
  padding-top: 2rem;
  text-align: center;
  font-weight: bold;
}

.wheelSvg {
  text-align: center;
  overflow: hidden;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-height: 800px) {
  .wheelSvg svg {
    width: 400px;
    height: 400px;
  }
}

/* Small devices (landscape & portrait phones, upto 768px) */
@media (max-width: 768px) {
  .wheelSvg svg {
    width: 490px;
    height: 490px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .wheelSvg svg {
    width: 290px;
    height: 290px;
  }
}
