.container {
  background: rgb(39, 39, 39);
  min-height: 100vh;
}

.content {
  display: grid;
  grid-template-columns: 100px auto 100px;
}

.card {
  grid-column: 2/4;
  background-color: #18181b;
  margin: 0px 50px 20px 20px;
  border-radius: 15px;
  box-shadow: 2px 2px #595959;
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr;
}

.details {
  padding: 40px;
}

.subText {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 3.5rem;
  padding-top: 2.5rem;
  text-align: center;
}

.clients {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
}

.imageContainer {
  width: 250px;
  height: 250px;
  padding-right: 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.image {
  width: 100%;
  margin: 2rem 0rem;
  filter: grayscale(1);
}

.imageContainer:hover .image {
  position: relative;
  object-fit: contain;
  filter: grayscale(0);
}

@media screen and (max-width: 1400px) {
  .clients {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .clients {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 790px) {
  .clients {
    grid-template-columns: 1fr;
  }
}

/* On screens that are 772px wide or less */
@media screen and (max-width: 772px) {
  .content {
    display: block;
  }
}

/* On screens that are 450px wide or less */
@media screen and (max-width: 450px) {
  .card {
    margin: 0px 10px 20px 10px;
  }
  .details {
    padding: 20px;
  }
}
