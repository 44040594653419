.headerText {
  text-align: center;
  padding: 40px 0;
  margin: 0;
  color: white;
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 10;
}

.headerText::before {
  width: 100%;
  content: attr(text);
  color: white;
  font-size: 5rem;
  z-index: 0;
  left: 50%;
  top: 1.3rem;
  opacity: 0.2;
  transform: translateX(-50%);
  position: absolute;
}

/* On screens that are 992px wide or less*/
/* @media screen and (max-width: 992px) {
  .headerText::before{
    font-size: 4rem;
    top: 5vh;
  }
} */

/* On screens that are 772px wide or less*/
@media screen and (max-width: 772px) {
  .headerText {
    font-size: 2rem;
  }

  .headerText::before{
    font-size: 3rem;
    top: 1.8rem;
  }
}

/* On screens that are 572px wide or less */
@media screen and (max-width: 572px) {
  .headerText::before{
    display: none;
  }
}

/* On screens that are 420px wide or less*/
@media screen and (max-width: 420px) {
  .headerText {
    font-size: 1.25rem;
  }
}

/* On screens that are 572px high or less */
@media screen and (max-height: 572px) {
  .headerText {
    padding-top: calc(6vh + 100px);
  }
}

/* On screens that are 572px high and 992px wide or less */
@media screen and (max-width: 992px) and (max-height: 572px) {
  .headerText {
    padding-top: calc(2vh + 100px);
  }

  .headerText::before{
    font-size: 4rem;
    top: calc(2vh + 90px);
  }
}

/* On screens that are 572px high and 772px wide or less */
@media screen and (max-width: 772px) and (max-height: 572px) {
  .headerText {
    padding-top: 6vh;
  }

  .headerText::before{
    font-size: 3rem;
    top: 4vh;
  }
}
