.container {
  background: rgb(39, 39, 39);
  min-height: 100vh;
}

.content {
  display: grid;
  grid-template-columns: 100px auto 100px;
}

.card {
  grid-column: 2/4;
  background-color: #18181b;
  margin: 0px 50px 20px 20px;
  border-radius: 15px;
  box-shadow: 2px 2px #595959;
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr;
}

.details {
  padding: 40px;
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 10px;
}

.contactSection {
  padding: 1.5rem;
}

.mainhead {
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.subhead {
  margin: 1rem 0rem;
  font-size: 2.5rem;
  font-weight: bold;
  background: -webkit-linear-gradient(90deg, #00c461, #c2fa39);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.25rem;
  line-height: 2rem;
}

.contacts {
  display: flex;
  align-items: center;
  filter: grayscale(1);
}

.contacts:hover {
  filter: grayscale(0);
}

.contacts:hover #clickable {
  text-decoration: underline;
  text-decoration-color: #00c461;
}

.logoContainer {
  width: 30px;
}

.logo {
  width: 100%;
}

.detail {
  color: white;
  font-size: 1.2rem;
  padding-left: 1rem;
}

.linkText {
  text-decoration: none;
}

.formSection {
  border: 1px solid #595959;
  box-shadow: 5px 5px #595959;
  /* padding: 0rem 2rem; */
}

.heading {
  margin: 0;
  padding: 1.5rem;
  font-size: 1.25rem;
  background-color: #262629;
}
.infoContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 1.5rem;
}

.info {
  background-color: #262629;
  padding: 1rem 1rem;
  border: none;
  border-bottom: 2px inset rgb(59 130 246);
}

.info:focus {
  border: 2px inset rgb(59 130 246);
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea,
select {
  color: white;
  font-size: 1.25rem;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textField;
  -moz-appearance: textfield;
}

.serviceContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 1.5rem;
}

.serviceGroup {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.label {
  padding-left: 0.75rem;
}

.radioGroup {
  display: flex;
  align-items: center;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

.budgetContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1.5rem;
  gap: 20px;
}

.descriptionContainer {
  padding: 1.5rem;
}

textarea {
  resize: none;
  width: calc(100% - 2rem);
  background-color: #262629;
  padding: 1rem;
  border: none;
  border-bottom: 2px inset rgb(59 130 246);
}

textarea:focus {
  border: 2px inset rgb(59 130 246);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.button {
  color: black;
  background-color: rgb(226, 226, 226);
  font-size: 1.5rem;
  font-weight: bold;
  width: 250px;
  height: 70px;
  border: none;
  border-radius: 5px;
  transform: translate(-10px, -10px);
  box-shadow: 10px 10px #595959;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.button:hover {
  color: white;
  background-color: #262629;
  box-shadow: 1px 1px #595959;
  transform: translate(0px, 0px);
  transition: all 0.2s ease-in-out;
}

.button:disabled {
  pointer-events: none;
  opacity: 0.5;
  color: white;
  background-color: #262629;
  box-shadow: 1px 1px #595959;
  transform: translate(0px, 0px);
  transition: all 0.2s ease-in-out;
}

.error {
  color: red;
  padding-top: 0.5rem;
}

#textType {
  padding-left: 1rem;
}

#fullWidth {
  text-align: center;
  grid-column: 1/4;
}

#description {
  text-align: center;
}

/* On screens that are 1430px wide or less */
@media screen and (max-width: 1430px) {
  .details {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .contactsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

/* On screens that are 1120px wide or less */
@media screen and (max-width: 1120px) {
  .infoContainer,
  .serviceContainer,
  .budgetContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  #fullWidth {
    grid-column: 1/3;
  }
}

/* On screens that are 850px wide or less */
@media screen and (max-width: 850px) {
  .infoContainer,
  .serviceContainer,
  .budgetContainer {
    grid-template-columns: 1fr;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"] {
    width: calc(100% - 2rem);
  }

  #fullWidth {
    grid-column: 1/2;
  }
  .contactsContainer {
    display: block;
  }
}

/* On screens that are 772px wide or less */
@media screen and (max-width: 772px) {
  .content {
    display: block;
  }
  .card {
    margin: 0px 10px 20px 10px;
  }
}

/* On screens that are 450px wide or less */
@media screen and (max-width: 450px) {
  .details {
    padding: 0px;
  }

  .formSection {
    border: none;
    box-shadow: none;
    /* padding: 0rem 2rem; */
  }

  .heading {
    border-left: 0.75rem solid #18181b;
    border-right: 0.75rem solid #18181b;
  }

  .infoContainer,
  .serviceContainer,
  .budgetContainer {
    padding: 1.5rem 0.75rem;
    gap: 10px;
  }

  .descriptionContainer {
    padding: 1.5rem 0.75rem;
  }
}
