.container {
  background: rgb(39, 39, 39);
  min-height: 100vh;
}

.content {
  display: grid;
  grid-template-columns: 100px auto 100px;
}

.card {
  grid-column: 2/4;
  background-color: #18181b;
  margin: 0px 50px 20px 20px;
  border-radius: 15px;
  box-shadow: 2px 2px #595959;
}

.cardContent {
  display: grid;
  grid-template-columns: 7fr 3fr;
}

.details {
  padding: 40px;
}

.name {
  font-size: 3.4rem;
  filter: drop-shadow(2px 4px 6px black);
  margin: 1rem 0;
  margin-top: 1rem;
}

.designation {
  margin: 1.5rem 0;
  margin-top: 0.5rem;
  font-size: 3.4rem;
  filter: drop-shadow(2px 4px 6px black);
  background: -webkit-linear-gradient(90deg, #00c461, #c2fa39);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.detail {
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.image {
  height: 100%;
  position: relative;
  background-image: radial-gradient(#cccccc 40%, #6e6e6e);
  border-radius: 0px 15px 15px 0px;
  overflow: hidden;
  box-shadow: -2px 0px 2px #595959;
  animation: animateBg 1.4s ease-in normal;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1);
  animation: scaleImage 1.4s ease-in normal;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.nameCard {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 70%;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);
  background-color: #18181b;
  font-size: 2rem;
  font-weight: 900;
  padding: 15px;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px -2px 4px #595959;
  animation: animateNameCard 1.4s ease-in normal;
}

.statusContainer {
  display: flex;
  justify-content: space-around;
  border-radius: 15px 15px;
  background-color: #262629;
  box-shadow: 1px 1px #595959;
  padding: 1rem;
  margin: 2rem 0rem;
}

.status {
  display: flex;
}

.text {
  padding-left: 1rem;
  font-size: 1.25rem;
  align-self: baseline;
}

.experience {
  color: rgb(59 130 246);
  font-weight: bold;
  font-size: 3.25rem;
  align-self: center;
}

.project {
  color: rgb(34 197 94);
  font-weight: bold;
  font-size: 3.25rem;
  align-self: center;
}

.clients {
  color: rgb(202 254 0);
  font-weight: bold;
  font-size: 3.25rem;
  align-self: center;
}

.buttonContainer {
  text-align: center;
  margin-top: 4rem;
}

.button {
  color: black;
  background-color: rgb(226, 226, 226);
  font-size: 1.5rem;
  font-weight: bold;
  width: 250px;
  height: 70px;
  border: none;
  border-radius: 5px;
  transform: translate(-10px, -10px);
  box-shadow: 10px 10px #595959;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.button:hover {
  color: white;
  background-color: #262629;
  box-shadow: 1px 1px #595959;
  transform: translate(0px, 0px);
  transition: all 0.2s ease-in-out;
}

@keyframes scaleImage {
  0% {
    transform: scale(1.8);
    opacity: 0;
  }
  50% {
    transform: scale(0.98);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes animateBg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animateNameCard {
  0% {
    opacity: 0;

    transform: translateX(50%);
  }
  100% {
    opacity: 1;
  }
}

/* On screens that are 1700px high or less*/
@media screen and (max-width: 1700px) {
  .name {
    font-size: 3.2rem;
    margin: 1rem 0rem;
  }

  .designation {
    font-size: 3.2rem;
    margin: 1rem 0rem;
  }

  .experience {
    font-size: 3rem;
  }

  .project {
    font-size: 3rem;
  }

  .clients {
    font-size: 3rem;
  }
}

/* On screens that are 1200px wide or less and 1600px wide or less*/
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .heroImage {
    object-position: top 0% left 20%;
  }

  .name {
    font-size: 3rem;
  }

  .designation {
    font-size: 3rem;
  }

  .detail {
    font-size: 1.2rem;
  }

  .experience {
    font-size: 2.75rem;
  }

  .project {
    font-size: 2.75rem;
  }

  .clients {
    font-size: 2.75rem;
  }

  .text {
    font-size: 1rem;
  }
}

/* On screens that are 1200px wide or less */
@media screen and (max-width: 1200px) {
  .cardContent {
    display: grid;
    grid-template-columns: 1fr;
  }
  .card {
    margin: 0px 10px 20px 10px;
  }
  .details {
    order: 2;
  }
  .image {
    order: 1;
    height: 525px;
    border-radius: 15px 15px 0px 0px;
  }
  .heroImage {
    object-position: top 20% left 40px;
  }
}

/* On screens that are 1000px wide or less */
@media screen and (max-width: 1000px) {
  .statusContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* On screens that are 800px wide or less */
@media screen and (max-width: 800px) {
  .name {
    font-size: 3rem;
  }
  .designation {
    font-size: 2.25rem;
  }
  .detail {
    font-size: 1.25rem;
  }
  .text {
    font-size: 1.25rem;
  }
  .experience {
    font-size: 2.5rem;
  }

  .project {
    font-size: 2.5rem;
  }

  .clients {
    font-size: 2.5rem;
  }
}

/* On screens that are 772px wide or less */
@media screen and (max-width: 772px) {
  .content {
    display: block;
  }
}

/* On screens that are 450px wide or less */
@media screen and (max-width: 450px) {
  .details {
    padding: 25px;
  }
  .text {
    font-size: 1.2rem;
  }
  .experience {
    font-size: 2rem;
  }

  .project {
    font-size: 2rem;
  }

  .clients {
    font-size: 2rem;
  }
}
