.aboutImage {
  height: 80px;
  width: 80px;
  filter: grayscale(1) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transition: all 0.5s ease-in-out;
  transform: translate(265px, 515px);
}

g:hover .aboutImage {
  height: 100px;
  width: 100px;
  filter: grayscale(0) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transform: translate(263px, 495px);
}

.educationImage {
  height: 80px;
  width: 80px;
  filter: grayscale(1) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transition: all 0.5s ease-in-out;
  transform: translate(265px, 18px) ;
}

g:hover .educationImage {
  height: 100px;
  width: 100px;
  filter: grayscale(0) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transform: translate(262px, 0px);
}

.skillsImage {
  height: 80px;
  width: 80px;
  filter: grayscale(1) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transition: all 0.5s ease-in-out;
  transform: translate(488px, 375px) 
}

g:hover .skillsImage {
  height: 100px;
  width: 100px;
  filter: grayscale(0) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transform: translate(472px, 360px) 
}

.contactImage {
  height: 80px;
  width: 80px;
  filter: grayscale(1) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transition: all 0.5s ease-in-out;
  transform: translate(485px, 145px) ;
}

g:hover .contactImage {
  height: 100px;
  width: 100px;
  filter: grayscale(0) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transform: translate(485px, 135px) ;
}

.portfolioImage {
  height: 80px;
  width: 80px;
  filter: grayscale(1) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transition: all 0.5s ease-in-out;
  transform: translate(60px, 138px) ;
}

g:hover .portfolioImage {
  height: 100px;
  width: 100px;
  filter: grayscale(0) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transform: translate(45px, 125px) ;
}

.experienceImage {
  height: 80px;
  width: 80px;
  filter: grayscale(1) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transition: all 0.5s ease-in-out;
  transform: translate(50px, 385px) ;
}

g:hover .experienceImage {
  height: 100px;
  width: 100px;
  filter: grayscale(0) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  transform: translate(42px, 365px) ;
}

svg > text {
  font-weight: 1000;
  font-size: 1.6em;
  text-shadow: 1px;
  transform: translate(135px,-80px);
}

svg g text,
svg g image {
  transform: rotate(-22deg);
}

g path {
  stroke: rgba(0, 0, 0, 0.3);
  filter: drop-shadow(2px 4px 6px black);
  stroke-width: 5px;
  stroke-dasharray: 308.5 475;
  transform-origin: center center;
  transform: scale(0.98);
  transform-box: fill-box;
  transition: all 0.5s ease-in-out;
}

g text {
  font-weight: 700;
  stroke: black;
}

g.activeMenu path {
  stroke: rgb(198, 207, 211);
  stroke-width: 10px;
  stroke-dasharray: 310 475;
}

g:hover {
  cursor: pointer;
}

g:hover path {
  fill: white;
  transition: all 0.5s ease-in-out;
  stroke-width: 0;
}

#hoveredMenuName {
  fill: white;
}