.menuContainer {
  z-index: 2;
  display: unset;
}

.sideMenu {
  position: -webkit-sticky;
  position: sticky;
  top: 15vh;
  width: 80px;
  height: 70vh;
  background-color: #18181b;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  box-shadow: 2px 2px #595959;
}

.menu {
  width: 90%;
  height: 9%;
  background-color: #262629;
  padding: 2px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px 1px #595959;
}

.menuImage {
  width: 70%;
  object-fit: contain;
  filter: grayscale(1) drop-shadow(rgba(0, 0, 0, 0.8) 3px 5px 2px);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#active .menuImage {
  filter: grayscale(0) !important;
}

.menu:hover .menuImage {
  filter: grayscale(0);
}

.menuLabels {
  position: absolute;
  z-index: 1;
  transform: translateX(40px);
  width: 0px;
  height: 60px;
  background-color: #000;
  padding: 0px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: 0rem;
  font-weight: bold;
  box-shadow: 2px 2px #595959;
  transition: all 0.5s ease-in-out;
}

.sideMenu #active {
  pointer-events: none;
}

.menu:hover .menuLabels {
  width: 170px;
  height: 60px;
  padding: 2px;
  font-size: 1rem;
  transform: translateX(150px);
  transition: all 0.5s ease-in-out;
}

/* On screens that are 772px wide or less */
@media screen and (max-width: 772px) {
  .menuContainer {
    display: none;
  }
}
/* On screens that are 992px wide or less */
@media screen and (max-width: 992px) {
  .sideMenu {
    width: 70px;
    padding: 6px;
  }

  .menu:hover .menuLabels {
    transform: translateX(140px);
  }
}

/* On screens that are 772px high or less */
@media screen and (max-height: 772px) {
  .sideMenu {
    width: 60px;
    padding: 4px;
  }

  .menu {
    width: 80%;
    padding: 1px;
  }

  .menuImage {
    width: 60%;
  }
}

/* On screens that are 572px high or less */
@media screen and (max-height: 572px) {
  .menuContainer {
    width: 100vw;
  }

  .sideMenu {
    top: 0;
    left: 15vw;
    width: 70vw;
    height: 80px;
    padding: 10px;
    display: flex;
    flex-direction: row;
  }

  .menu {
    width: 9%;
    height: 90%;
  }

  .menuLabels {
    width: 170px;
    height: 60px;
    opacity: 0;
    transform: translateY(70px);
  }

  .menu:hover .menuLabels {
    width: 170px;
    height: 60px;
    opacity: 1;
    transform: translateY(90px);
  }
}

/* On screens that are 992px wide  and 572 high or less */
@media screen and (max-width: 992px) and (max-height: 572px) {
  .sideMenu {
    height: 70px;
    padding: 7px;
  }
}

/* On screens that are 772px high and 572 high or less */
@media screen and (max-width: 772px) and (max-height: 572px) {
  .sideMenu {
    display: none;
  }
}
