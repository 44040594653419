.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
}
.skillContainer {
  display: flex;
  align-items: center;
}
.imageContainer {
  width: 48px;
  height: 48px;
  padding-right: 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.image {
  height: 100%;
  position: relative;
  object-fit: contain;
  filter: grayscale(1);
}
.container:hover .image {
  filter: grayscale(0);
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}
.skill {
  font-weight: bold;
  align-self: center;
  font-size: 1.5rem;
}

.skillMeter {
  display: flex;
  align-self: center;
}
.blueBox {
  background-color: rgb(34 197 94);
  border: 1px solid black;
  width: 20px;
  height: 20px;
  filter: drop-shadow(2px 4px 6px black);
}
.greyBox {
  background-color: #3d3d47;
  border: 1px solid black;
  width: 20px;
  height: 20px;
  filter: drop-shadow(2px 4px 6px black);
}

/* On screens that are 550px wide or less */
@media screen and (max-width: 550px) {
  .imageContainer {
    width: 40px;
    height: 40px;
  }
  .skill {
    font-size: 1.25rem;
  }
  .blueBox {
    width: 15px;
    height: 15px;
  }
  .greyBox {
    width: 15px;
    height: 15px;
  }
}

/* On screens that are 450px wide or less */
@media screen and (max-width: 450px) {
  .container {
    padding: 0 0.5em;
  }
}