.container {
  background: rgb(39, 39, 39);
  min-height: 100vh;
}

.content {
  display: grid;
  grid-template-columns: 100px auto 100px;
}

.card {
  grid-column: 2/4;
  background-color: #18181b;
  margin: 0px 50px 20px 20px;
  border-radius: 15px;
  box-shadow: 2px 2px #595959;
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr;
}

.details {
  padding: 40px;
}

.date {
  font-size: 1.25rem !important;
  /* color: greenyellow !important; */
  font-weight: bold !important;
}

#designation {
  font-size: 1.75rem;
  background: -webkit-linear-gradient(90deg, #00c461, #c2fa39);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#company {
  font-size: 1.5rem;
  padding-top: 1rem;
}

#description {
  font-size: 1.25rem;
}

/* On screens that are 1500px wide or less */
@media screen and (max-width: 1500px) {
  #designation {
    font-size: 1.5rem;
  }
  
  #company {
    font-size: 1.25rem;
  }
}

/* On screens that are 772px wide or less */
@media screen and (max-width: 772px) {
  .content {
    display: block;
  }
  .card {
    margin: 0px 10px 20px 10px;
  }
}

/* On screens that are 650px wide or less */
@media screen and (max-width: 650px) {
  .date{
    font-size: 1.2rem !important;
  }
  #designation {
    font-size: 1.25rem;
  }
  
  #company {
    font-size: 1rem;
  }

  #description{
    font-size: 1rem;
  }
}

/* On screens that are 450px wide or less */
@media screen and (max-width: 450px) {
  .details {
    padding: 15px;
  }
}