.container {
  display: grid;
  grid-template-columns: 0px auto;
}

.sidemenu {
  grid-column: 1/2;
}

.children {
  grid-column: 2/3;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(255, 255, 255, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* On screens that are 572px high or less */
@media screen and (max-height: 772px) {
  .sidemenu {
    grid-column: 2/3;
  }
}
/* On windows that zooms to 125% by default */
@media (-webkit-device-pixel-ratio: 1.25) {
  .children {
    zoom: 0.8;
  }
}
